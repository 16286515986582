.profile-wrapper .profile-content-box {
  display: flex;
}
.profile-content-box .proflie-img-box {
  text-align: center;

  margin-right: 3rem;
}
.profile-content-box .proflie-img-box .profile-img {
  width: 200px;
  margin-bottom: 1rem;
}
.profile-content-box .proflie-img-box .profile-img img {
  max-width: 200px;
}
.file-upload-box {
  text-align: center;
}
.file-upload-box .preview,
.file-upload-box .basic-btn {
  display: inline-block;
  background-color: #2e7d32;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 5px;
  margin: 0 0.2rem;
  border: none;
  width: 70px;
  text-align: center;
  height: 70px;
}
.file-upload-box .preview:hover,
.basic-btn:hover {
  cursor: pointer;
  color: yellow;
}

.file-upload-box input {
  display: none;
}
.profile-player-stat-box {
  border: 1px solid #056186;
  background-color: #fff;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 1rem;
}
.profile-player-stat-box .label {
  display: inline-block;
  width: 10rem;
}
.profile-player-stat-box .player-stat-row {
  margin: 0.5rem 0;
}
/************************player page ************************/
.videoinfo-edit-container {
  display: flex;
  margin: 0.5em auto;
  border: 1px solid #8bc1c7;
  padding: 1em;
}
.videoinfo-edit-container .thumbnail-box {
  width: 200px;
  min-height: 100px;
  background-color: #ddd;
  margin-right: 1rem;
}
.videoinfo-edit-container .thumbnail-box img {
  width: 100%;
}
.search-input {
  position: relative;
  display: inline-block;
}
.search-input .search-icon {
  position: absolute;
  left: 85%;
  top: 30%;
  color: #aaa;
}
.input-box .label {
  margin-bottom: 0.5rem;
  color: #056186;
  font-weight: 600;
}
.multi-row-container {
  display: flex;
  justify-content: space-between;
}
.sub-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.multi-row-container .multi-row-container-item {
  width: 48%;
  padding: 1em;
  background-color: #fff;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
}
.exam-txt {
  font-size: 1.1rem;
  color: #056186;
}
/* tab style */
.tab-menu-container {
  display: flex;
  background-color: #fff;
}
.tab-menu-container .tab-menu {
  cursor: pointer;
  width: 30%;
  text-align: center;
  padding: 0.5em;
  font-size: 1.2rem;
  font-weight: 600;
}
.tab-menu-container .selected {
  color: rgb(64, 132, 75);
  border-bottom: 3px solid rgb(64, 132, 75);
}
.tab-container .tab-content {
  margin-top: 1rem;
  width: 100%;
}
.tution-fee-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.staff-list-subsection {
  background-color: #fff;
  padding: 1em;
  margin: 1rem 0;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}
.tuitionfee {
  background-color: #fff;
  padding: 1em;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}
.staff-serach-filter-item {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}
.staff-serach-filter-item .label {
  width: 5rem;
  color: #056186;
  font-weight: 600;
}
.staff-serach-filter-item .value {
  border-radius: 5px;
  width: 15rem;
  border: 1px solid #bbb;
  color: #bbb;
  padding: 0.3em;
}
.selected-info-container {
  display: flex;
  width: 100%;
  margin: 1rem 0;
}

.selected-info-item {
  background-color: #fff;
  padding: 1em;
  width: 44%;
}

.selected-info-item .selected-info-row {
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  color: #056186;
  font-weight: 600;
}
.selected-info-item .selected-info-row .label {
  width: 5rem;
}
.staff-search-result-container {
  display: flex;
  justify-content: space-between;
}
.staff-search-result-container .staff-search-result-item {
  width: 49%;
  background-color: #fff;
  padding: 1em;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}
.item-box {
  border: 1px solid #8bc1c7;
  padding: 0.5em;
  margin: 1rem 0;
}
.item-box .team-image-container {
  display: flex;
}
.image-box {
  width: 200px;
  height: 100px;
  object-fit: contain;
  background-color: #aaa;
  text-align: center;
  line-height: 100px;
  font-size: 1.5rem;
  color: #056186;
}

.item-box .team-image-container .title-url-box {
  padding: 1em;
  margin-left: 1rem;
}
.title-url-box .label {
  color: #056186;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.my-select-box {
  width: 200px;
  height: 50px;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.5em;
  border-radius: 5px;
  color: #056186;
  font-weight: 700;
}
