html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
div {
  box-sizing: border-box;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
/* font-size */
.txt_3 {
  font-size: 1.3rem;
}
.txt_bold700 {
  font-weight: 700;
}
/* table design */

/* header design */

.body-container {
  /* background-color: #efefef; */
}

/* bullet text */
.info {
  line-height: 2rem;
  padding-left: 15px;
}
.info .info_group {
  position: relative;
  display: flex;
}
.info_group .cm_bar {
  display: inline-block;
  position: absolute;
  top: 5px;
  left: -15px;
  bottom: -5px;
  width: 3px;
  height: auto;
  background-color: #f8f3f2;
}
.info dt {
  float: left;
  padding-right: 13px;
  letter-spacing: -0.3px;
  color: #939396;
  width: 10rem;
}
.info dd {
  display: block;
  margin-left: 1rem;
}
.section-title {
  font-size: 1.5rem;
  color: #001c48;
  margin-bottom: 1rem;
}
.section-title::before {
  display: inline-block;
  content: "";
  width: 0.3rem;
  height: 1.4rem;
  background-color: #001c48;
  margin-right: 0.5rem;
}
.section-title {
  font-size: 1.5rem;
  color: #001c48;
  margin-bottom: 1rem;
}
.section-sub-title {
  display: block;
  color: #001c48;
  margin: 1rem 0;
  font-weight: 600;
}
.input-box {
  margin-bottom: 0.5rem;
}
.input-box input[type="text"] {
  height: 1.2rem;
  width: 200px;
  margin-right: 0.5rem;
}
.input-box select {
  width: 200px;
  height: 1.5rem;
}
.input-box .stat-label {
  display: inline-block;
  width: 150px;
}
.input-box .info-box {
  margin: 1rem 0;
  color: #001c48;
}
.section-sub-title::before {
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #001c48;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.item-list li {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-top: 1rem;
}
.item-list li::marker {
  color: #001c48;
}
.career-group .career-duration {
  display: inline-block;
  color: #939396;
  width: 10rem;
}

.contact-info-row {
  display: flex;
  margin-bottom: 0.7rem;
}
.contact-info-row .contact-info-icon-box {
  display: inline-block;
  width: 2rem;
}
/******************************header-design******************************/
.header-container {
  background-color: #fff;
  height: 150px;
}
.header-container .header-login {
  margin: 0 auto;
  background-color: #f1f3f9;
}
.header-container .header-login .login-content {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  justify-content: end;
}
.header-login .login-content .login-item {
  padding: 1em;
  cursor: pointer;
}
.header-login .login-content .login-item:hover a,
.header-login .login-content .login-item:hover {
  color: #146ebe;
}
.login-item .lang-select {
  display: inline-block;
  position: relative;
}
.login-item .lang-select .dropdown-trigger {
  color: #000;
  position: absolute;
  width: 100%;
  text-align: center;
  text-align: left;
}
.lang-select .dropdown-trigger p {
  text-align: center;
  padding: 0.5em;
}
.lang-select .dropdown-trigger p:hover {
  color: #146ebe;
}
.lang-select .dropdown-trigger p:first-child {
  margin-top: 1rem;
}
.header-container .header-content {
  width: 1200px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  text-align: center;
}
.header-container .header-content .header-logo {
  width: 15%;
}
.header-container .header-content .header-logo img {
  width: 100%;
}
.header-container .header-content .header-menu {
  width: 70%;
}
.header-container .header-content .header-login {
  width: 10%;
}
.header-container .header-menu > ul {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
}
.header-container .header-menu .dp1::after {
  display: none;
  content: "";
  height: 5px;
  background-color: #146ebe;
  position: relative;
  bottom: 0;
  margin-top: -5px;
}
.header-container .header-menu .dp1:hover::after {
  display: block;
}
.header-container .header-menu .dp1:hover a {
  color: #146ebe;
}
.header-container .header-menu ul .dp1 a {
  display: inline-block;
  height: 100%;
  line-height: 50px;
  width: 100%;
  color: #000;
}
.header-container .header-menu .dp2 {
  visibility: hidden;
  width: 100%;
  position: absolute;
  font-weight: 500;
  font-size: 1rem;
  transform: translateX(-10px);
}
.header-container .header-menu ul .dp1:hover .dp2 {
  transform: translateX(0);
  transition-duration: 0.3s;
  visibility: visible;
  background-color: #eee;
  text-align: center;
  z-index: 10;
}

.header-container .header-menu .dp2 a {
  color: #001c48;
}
.header-container .header-menu .dp2 a:hover {
  text-decoration: underline;
}

/******************************footer-design******************************/
.footer-container {
  background-color: #eee;
  font-size: 1rem;
  color: #001c48;
  margin-bottom: 5rem;
}
.footer-container .footer-content {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  align-items: center;
}
.footer-content .footer-logo {
  width: 20%;
}
.footer-content .footer-logo img {
  width: 100%;
}
.footer-container .footer-content .footer-info {
  padding: 1em;
}
.footer-container .footer-content .footer-info div {
  margin: 1em 0;
}

.footer-content .footer-menu-ul {
  display: flex;
}
.footer-content .footer-menu-ul li {
  margin-right: 20px;
}
.footer-content .footer-menu-ul li a {
  color: #001c48;
  font-weight: 600;
}

.footer-container .footer-right,
.footer-container .footer-address {
  font-size: 0.9rem;
}
