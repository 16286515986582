p {
  margin: 0;
}

.icon {
  width: 100px;
  height: 50;
  pointer-events: none;
}

.file {
  display: none;
}

.file::file-selector-button {
  font-size: 14px;
  background: #fff;
  border: 1px solid #111;
  border-radius: 12px;
  padding: 1rem 1rem;
  cursor: pointer;
}

.preview {
  display: inline-block;
  width: 100%;
  height: 2rem;
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
  border: 3px dashed #eee;
  padding: 3.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.preview:hover {
  border-color: #111;
}

.preview.active {
  background-color: #efeef3;
  border-color: #111;
}

.preview_msg {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 1rem 0 1rem;
  width: "100%";
}

.preview_desc {
  margin: 0;
  font-size: 1rem;
  width: 100%;
  color: red;
}

.preview_info {
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.preview_info .info_key {
  display: block;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;
}

.preview_info .info_value {
  font-size: 14px;
}
