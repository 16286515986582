.staff-wanted-cards-wapper{
    width: 100%;
} 
.staff-wanted-cards-wapper .wanted-card-wrapper{
    width: 100%;    
    padding: 0;
}
.staff-wanted-cards-wapper .staff-wanted-cards-ul{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
}
.staff-wanted-cards-wapper .staff-wanted-cards-ul li{
    box-sizing: border-box;
    display: block;
    width: 31%;
    height: 200px;
    border: 1px solid #eee;
    margin: 0.5rem;
    padding: 1em;
}
.staff-wanted-cards-wapper .staff-wanted-cards-ul li:hover{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.wanted-card-wrapper .logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;    
    border-bottom: 1px solid #ccc;
}
.wanted-card-wrapper .job-dday-wrapper .dday{
    color: #FFF;
    font-size: 0.95rem;
    background-color: #ee5656;
    border-radius: 0.2rem;
    padding: .2em;    
}
.wanted-card-wrapper .logo-wrapper img{
    width: 8rem;    
}
.wanted-card-wrapper .logo-wrapper .logo-name{
    font-size: 1.3rem;
    font-weight: 600;
    color: #555;
}
.wanted-card-wrapper .content-wrapper{
    margin-top: 1rem;
}
.wanted-card-wrapper .content-wrapper span{
    display: block;
    margin-bottom: 0.5rem;
}
.wanted-card-wrapper .content-wrapper .title{
    color: #001c48;
    font-weight: 600;
}
.wanted-card-wrapper .content-wrapper .position{
    font-size: 0.96rem;
    color: #475067;
}
.wanted-card-wrapper .content-wrapper .wanted-detail{
    font-size: 0.9rem;
    color: #475067;
}
.content-wrapper .wanted-detail{
    display: flex;
}
.content-wrapper .wanted-detail span{
    display: inline-block;
    width: 30%;    
    margin-right: 0.5rem;
    border-right: 1px solid #aaa;
}
.content-wrapper .wanted-job-type{
    display: flex;
}
.content-wrapper .wanted-job-type .job-chip{
    background-color: #DEFAF6;
    font-size: 0.9rem;
    padding: 0.3em 1em;
    margin-right: 1em;
    margin-top: 0.5em;

}
