.player-info-section {
  margin-bottom: 1rem;
}
.player-profile-container {
  display: flex;
  padding: 1rem;
}
.player-profile-container .player-basic-info-col {
  width: 50%;
}
.player-profile-container .player-position-col {
  width: 60%;
  border-left: 1px solid #eee;
  padding-left: 2rem;
}
.player-profile-container .player-profile-img-container {
  width: 16rem;
}
.player-profile-container .player-profile-img-container img {
  width: 100%;
}
.player-profile-container .player-profile-name {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.player-profile-container .player-profile-affiliation {
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.player-profile-info-container .player-dt {
  width: 7rem;
}

.player-profile-container .position-field-img {
  width: 16rem;
  margin-top: 2.2rem;
}
.player-profile-container .position-field-img img {
  width: 100%;
}
.player-profile-container .section-title {
  display: block;
  margin-bottom: 1rem;
}
.player-profile-container .contact-info-container {
  display: flex;
  padding-left: 0.5rem;
}
.player-profile-container .contact-info-container .agent-img-box {
  width: 13rem;
}
.player-profile-container .contact-info-container .agent-img-box img {
  width: 100%;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}
.player-profile-container .contact-info-container .contact-info-row {
  display: flex;
}
.player-profile-container .agent-info-box {
  margin-left: 1.5rem;
  padding: 1rem;
}
.player-agent-container .contact-info-container {
  border: 1px solid #eee;
  padding: 1rem;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}
.agent-info-box .agent-info-name {
  display: inline-block;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.player-carrer-container {
  width: 100%;
}
.player-carrer-container .player-past-school {
  margin-top: 1rem;
  width: 70%;
}
.player-video-container {
  width: 100%;
}
.player-video-container .player-video-row {
  display: flex;
}
.player-video-card {
  border: 1px solid #eee;
  width: 33%;
}

.player-video-card .video-meta-box {
  padding: 0.5em;
}
.player-video-card .video-meta-box span {
  display: block;
}
.player-video-card .video-meta-box .video-title {
  margin-bottom: 0.5rem;
}
.player-video-card .video-meta-box .video-date {
  font-size: 1rem;
  color: #939396;
}
