.coach-info-section{
    padding: 1rem;
    margin-bottom: 1rem;
}
.coach-profile-container{    
    display: flex;
    width: 100%;            
}
.coach-profile-container .coach-profile-img-container img{
    width: 15rem;
    margin-right: 1rem;
}

.coach-team-history-container{    
    width: 100%;
}
.coach-team-history-paper{  
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
}
.coach-philosophy-box{
    background-color: #eee;
    margin-top: 1rem;
    padding: 2rem;
}
.coach-info-section .basic-info-table{
    margin-top: 1rem;
}