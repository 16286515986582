.basic-info-table {
  font-size: 1em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

.basic-info-table th {
  text-align: left;
}
.basic-info-table tr:nth-child(even) {
  background-color: #eee;
}

.basic-info-table thead {
  font-weight: bold;
  color: #fff;
  background: #001c48;
}

.basic-info-table td,
.basic-info-table th {
  padding: 1em;
  vertical-align: middle;
  text-align: center;
  font-size: 1.2rem;
}

.basic-info-table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.basic-info-table a {
  color: #73685d;
}

@media all and (max-width: 768px) {
  /* table, thead, tbody, th, td, tr {
	  display: block;
	}
	
	th {
	  text-align: right;
	}
	
	table {
	  position: relative; 
	  padding-bottom: 0;
	  border: none;
	  box-shadow: 0 0 10px rgba(0,0,0,.2);
	}
	
	thead {
	  float: left;
	  white-space: nowrap;
	}
	
	tbody {
	  overflow-x: auto;
	  overflow-y: hidden;
	  position: relative;
	  white-space: nowrap;
	}
	
	tr {
	  display: inline-block;
	  vertical-align: top;
	}
	
	th {
	  border-bottom: 1px solid #a39485;
	}
	
	td {
	  border-bottom: 1px solid #e5e5e5;
	} */
}

/* player list table */
.player-list-table {
  font-size: 0.9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  overflow: hidden;
}

.player-list-table th {
  cursor: pointer;
  text-align: center;
  padding: 1rem;
}
.player-list-table tr:nth-child(even) {
  background-color: #eee;
}
.player-list-table thead {
  font-weight: bold;
  color: #fff;
  background: #056186;
}

.player-list-table td {
  padding: 0.3em 0.5em;
  vertical-align: middle;
  text-align: center;
}
.player-list-table .data-tr:hover {
  cursor: pointer;
  background-color: #e9fbfd;
  color: #056186;
}
.player-list-table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.player-list-table a {
  color: #73685d;
}
.player-list-table .player-list-icon {
  width: 4rem;
  padding: 0.2rem;
}
.player-list-table .player-list-icon img {
  display: inline-block;
  width: 100%;
  height: 4rem;
  overflow: hidden;
}
.player-list-table .player-list-icon .no-image-box {
  display: inline-block;
  padding: 0.7em;
  width: 50px;
}
.player-list-table-more-wrapper {
  margin: 1rem;
}
.player-list-table-more-wrapper .show-more-btn {
  cursor: pointer;
  color: #eee;
  display: block;
  width: 7rem;
  padding: 0.5rem;
  margin: 0 auto;
  background-color: #054e6b;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.player-list-table-more-wrapper .show-more-btn:hover {
  color: #baeaff;
}

.coach-position-prefer-table {
  border-collapse: collapse;
}
.coach-position-prefer-table tr {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.coach-position-prefer-table th,
.coach-position-prefer-table td {
  padding: 0.3rem;
}
.coach-position-prefer-table th {
  background-color: #baeaff;
  vertical-align: middle;
  font-weight: 500;
}
