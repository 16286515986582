.swiper-button-next-i,
.swiper-button-prev-i {
  width: 40px;
  height: 40px;
  background-color: #444;
  opacity: 0.7;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  z-index: 20;
  color: aliceblue;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.swiper-button-next-i {
  right: -20px;
}
.swiper-button-prev-i {
  left: -20px;
}

.swiper-button-next-i:hover,
.swiper-button-prev-i:hover {
  background-color: #777;
  opacity: 0.9;
}

.imageSwiper {
  height: 260px;

  width: 80%;
  background-color: black;
}

.imageSwiper img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
}
.imageSwiper .swiper-button-next-i,
.imageSwiper .swiper-button-prev-i {
  border-radius: 10%;
  height: 45px;
  width: 30px;
  text-align: center;
}
.imageSwiper .swiper-button-next-i:hover,
.imageSwiper .swiper-button-prev-i:hover {
  background-color: #000;
  opacity: 0.7;
  color: #fff;
}
.imageSwiper .swiper-button-next-i {
  right: 0;
}
.imageSwiper .swiper-button-prev-i {
  left: 0;
}
