.carousel-container{
    width: 350px;
    height: 250px;
    margin: auto;
    overflow: hidden;
    animation-name: fade;
    animation-duration: 1.5s;
    position: relative;    
    box-shadow: 0 0.2rem 0.2rem rgba(0,0,0,0.30), 0 0.2rem 0.2rem rgba(0,0,0,0.22);
}
.carousel-container .img-container{
    display: flex; 
    margin: 0 auto;
    margin-bottom: 2em;          
}
.carousel-container img{
    width: 350px;
    height: 250px;
    object-fit:cover;
}
.carousel-container a{   
    display: inline; 
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;    
    opacity: 0.5;
}
  
.carousel-container .carousel-next-btn {
    right: 0;
    border-radius: 3px 0 0 3px;
}
.carousel-prev-btn:hover, .carousel-next-btn:hover {
    background-color: rgba(0,0,0,0.8);
  }
.dot-container .dot {
    cursor: pointer;
    height: 0.7rem;
    width: 0.7rem;
    margin-top: 0.7rem;
    margin-left: 0.5rem;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}  
.carousel-container .dot:hover {
    background-color: #717171;
}
.dot-container{
    margin: auto;
    width: 400px;
    text-align: center;
}