.mypage-wrapper {
  display: flex;
}
.mypage-wrapper .mypage-sidebar-wrapper {
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid #62c2cc;
}
.mypage-sidebar-wrapper .staff-sidebar {
  width: 300px;
  padding: 1rem;
  font-size: 1.2rem;
}
.sidebar-menu {
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  border: 1px solid #eee;
  background-color: #fff;
  font-size: 1.2rem;
}
.selected-menu {
  background-color: #339af0;
  border: 1px solid #339af0;
  color: #fff;
}
.staff-sidebar .sidebar-menu .sidebar-menu-icon {
  display: inline-block;
  width: 50px;
  text-align: center;
}
.mypage-wrapper .mypage-body-wrapper {
  width: 1200px;
  margin-left: 5rem;
}
.mypage-wrapper .mypage-body-wrapper .mypage-body-item-wrapper {
  background-color: #f8f9fc;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #62c2cc;
  border-radius: 5px;
  width: 1200px;
}
.mypage-body-item-wrapper-bound-height {
  max-height: 600px;
  overflow-y: scroll;
}
.mypage-wrapper
  .mypage-body-wrapper
  .mypage-body-item-wrapper
  .mypage-body-item-title-wrapper {
  display: flex;
  align-items: center;
}
.datePicker {
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  width: 80%;
  color: black;
  text-align: center;
  border: none;
  height: 100%;
  font-size: 1.2rem;
  text-align: center;
}
.datePicker:hover {
  cursor: pointer;
}
.career-list-item-item .react-datepicker__input-container {
  text-align: center;
  width: 200px;
}
.mypage-body-item-wrapper .add-btn {
  background-color: #ffd43b;
  color: #183153;
  border: none;
  font-size: 1rem;
  padding: 0.4em 2em;
  border-radius: 4px;
}
.mypage-body-item-wrapper .add-btn:hover {
  color: #1e8ffa;
}
.mypage-body-item-wrapper .add-btn .add-btn-icon {
  margin-right: 0.5em;
}
.career-list-item {
  display: flex;
  align-items: center;
  border: 1px solid #8bc1c7;
  padding: 0.5rem;
  margin: 0.2rem 0;
  width: 100%;
}
/* .career-list-item .career-list-item-item {
  margin-right: 2rem;
  background-color: #fff;
  height: 3rem;
  line-height: 3rem;
  padding: 0 0.5rem;
  border: 1px solid #62c2cc;
  border-radius: 5px;
} */
.career-list-item .career-list-item-item input {
  height: 100%;
  border: none;
}

/* dialog style */
.dialog-search-wrapper {
  padding: 0 15px;
}
.dialog-search-wrapper .table {
  width: 70%;
  border: 1px solid black;
}
.mypage-header {
  height: 50px;
  background-color: #f8f9fc;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.mypage-header .header-logo img {
  width: 150px;
}
.mypage-header .member-info button {
  cursor: pointer;
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: none;
}
