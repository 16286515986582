.team-profile-container {
  display: flex;
}
.team-info-section {
  padding: 1rem;
  margin-bottom: 1rem;
}
.team-info-section .section-title {
  display: block;
}
.team-info-section .contact-info-container {
  margin-left: 0.5rem;
}
.team-profile-img-container {
  border: 1px solid #001c48;
  height: 200;
}
.team-profile-img-container .team-profile-team-name {
  font-size: 1.5rem;
  text-align: center;
  background-color: #001c48;
  padding: 0.7rem;
  color: #fff;
  font-weight: 600;
}
.team-profile-container .display-none {
  visibility: hidden;
  border: none;
}
.team-profile-container img {
  width: 14rem;
}
.coach-profile-info-cotainer {
  margin-left: 1rem;
}
.coach-profile-info-cotainer dd,
.coach-profile-info-cotainer dt {
  font-size: 1.3rem;
}
.team-info-section .basic-info-table {
  margin-top: 1rem;
}
.team-position-btn-container {
  text-align: center;
  margin: 0 auto;
  width: auto;
}
.team-player-pos-grade-box {
  display: inline-block;
  width: auto;
  margin-bottom: 2rem;
}
.team-position-btn-container .team-position-btn {
  width: 7rem;
  border-radius: 0;
}
.team-position-player-table {
  display: inline-block;
  text-align: center;
  background-color: #0e0e0e;
  margin-top: 1rem;
}
.team-position-player-table th {
  display: inline-block;
  height: 6rem;
}
.team-position-player-table td {
  display: inline-block;
  height: 100%;
  padding: 1rem;
  border: 1px solid white;
  display: table-cell;
  vertical-align: middle;
}
.team-position-player-table .table-position-txt {
  border-radius: 5%;
  color: antiquewhite;
  font-size: 1rem;
  padding: 0.5em;
}
.team-position-player-table .table-position-color-fw {
  background-color: #b22222;
}
.team-position-player-table .table-position-color-mf {
  background-color: #228b22;
}
.team-position-player-table .table-position-color-df {
  background-color: #0066b3;
}
.team-position-player-table .table-position-color-gk {
  background-color: #ff8000;
}
.team-position-player-table .table-position-num {
  display: inline-block;
  border-radius: 50%;
  background-color: yellow;
  color: #000;
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
}
.team-picture-container {
  margin-left: 15rem;
}
.contact-info-container {
  font-size: 1.3rem;
}
.team-image-container {
  display: flex;
}
.team-image-card {
  margin: 1rem;
}
.team-image-card .team-image-title {
  text-align: center;
  font-size: 1.2rem;
  padding: 1em;
  background-color: #0066b3;
  color: #fff;
  font-weight: 600;
}
.team-image-card .team-image-box {
  width: 400px;
}
.team-image-card .team-image-box img {
  width: 100%;
}
.team-image-card .team-image-box img:hover {
  scale: 2;
  transition-duration: 1s;
  transition-delay: 1s;
}
