@media screen and (max-width: 600px) {
  :root {
    font-size: 11px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  :root {
    font-size: 12px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {
  :root {
    font-size: 15px;
  }
}
@media screen and (min-width: 1920px) {
  :root {
    font-size: 15px;
  }
}
